import { navigate, WFRoute } from "@xatom/core";
import { ADMIN_PATH, ADMIN_PATHS } from "../../config";
import { adminMiddleware } from "../../modules/auth/admin";
import { initAdminHeader } from "../../modules/common/adminHeader";
import { fetchMe } from "../../modules/common/fetchMe";

export const adminRoutes = () => {
  new WFRoute(`${ADMIN_PATH}/(.*)`).execute(() => {
    new WFRoute(ADMIN_PATHS.login)
      .withMiddleware(adminMiddleware, "NONE", "allow", {
        onError: () => {
          navigate({
            to: ADMIN_PATHS.dashboardOnly,
            type: "replace",
          });
        },
      })
      .execute(() =>
        import("../../modules/admin/auth/signIn")
          .then(({ adminSign }) => adminSign())
          .catch(console.log)
      );
    new WFRoute(ADMIN_PATHS.acceptInvite)
      .withMiddleware(adminMiddleware, "NONE", "allow", {
        onError: () => {
          navigate({
            to: ADMIN_PATHS.dashboardOnly,
            type: "replace",
          });
        },
      })
      .execute((params: any) =>
        import("../../modules/admin/auth/invite")
          .then(({ acceptInvite }) => acceptInvite(params))
          .catch(console.log)
      );

    new WFRoute(ADMIN_PATHS.dashboardOnly)
      .withMiddleware(adminMiddleware, "ADMIN", "allow", {
        onError: () => {
          navigate({
            to: ADMIN_PATHS.login,
            type: "replace",
          });
        },
      })
      .execute(() => {
        initAdminHeader();
        import("../../modules/admin/workspace/landing")
          .then(({ adminDashboardView }) =>
            fetchMe(() => {
              adminDashboardView();
            })
          )
          .catch(console.log);
      });
    new WFRoute(`${ADMIN_PATHS.dashboardOnly}/(.*)`)
      .withMiddleware(adminMiddleware, "ADMIN", "allow", {
        onError: () => {
          navigate({
            to: ADMIN_PATHS.login,
            type: "replace",
          });
        },
      })
      .execute(() => {
        initAdminHeader();
        fetchMe(() => {
          new WFRoute(ADMIN_PATHS.workspaceList).execute(
            () =>
              import("../../modules/admin/workspace/list")
                .then(({ adminWorkspaceList }) =>
                  adminWorkspaceList()
                )
                .catch(console.log)
          );
          new WFRoute(ADMIN_PATHS.workspaceCreate).execute(
            () =>
              import("../../modules/admin/workspace/create")
                .then(({ adminWorkspaceCreate }) =>
                  adminWorkspaceCreate()
                )
                .catch(console.log)
          );
          new WFRoute(
            `${ADMIN_PATHS.workspaceManage}`
          ).execute(({ id }) =>
            import("../../modules/admin/workspace/manage")
              .then(({ adminWorkspaceManage }) =>
                adminWorkspaceManage(id)
              )
              .catch(console.log)
          );
          new WFRoute(`${ADMIN_PATHS.team}`).execute(() =>
            import("../../modules/admin/team/team")
              .then(({ manageTeam }) => manageTeam())
              .catch(console.log)
          );
        });
      });
  });
};
