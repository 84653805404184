import { ASSET_ENDPOINT } from "../../config";
import { adminQL, publicQL } from "../../graphql";
import {
  AdminMeDocument,
  AdminWithAgency,
} from "../../graphql/graphql";
import { browserCache } from "../utils/cache";
import { notify } from "../utils/noti";
import { initSidebarBranding } from "../utils/sidebarBranding";

export const fetchMe = (
  cb: (data: AdminWithAgency) => void
) => {
  const req = adminQL.query(AdminMeDocument);
  const cached = browserCache.getCache("adminMe");
  let tries = 0;
  let isCallbackCalled = false;
  const doBrandingChanges = (data: AdminWithAgency) => {
    initSidebarBranding({
      logoLg: `${ASSET_ENDPOINT}/${data.agency.logoLg}`,
      logoSm: `${ASSET_ENDPOINT}/${data.agency.logoSm}`,
      name: data.agency.name,
    });
  };
  const runCallback = (data: AdminWithAgency) => {
    doBrandingChanges(data);
    if (isCallbackCalled) {
      return;
    }
    isCallbackCalled = true;
    cb(data);
  };
  if (cached) {
    runCallback(cached.data as AdminWithAgency);
    return;
  }

  req.onData((data) => {
    runCallback(data.adminMe as AdminWithAgency);
    browserCache.setCache(
      "adminMe",
      {
        type: "json",
        data: data.adminMe as AdminWithAgency,
      },
      1000 * 60 * 60 * 24 * 30 // 30 days
    );
  });

  req.onError(() => {
    notify.error("Unable to fetch agency information.");
    tries++;
    if (tries > 5) return;
    setTimeout(() => {
      req.fetch();
    }, 1000 * (tries / 2));
  });
  req.fetch();
};
