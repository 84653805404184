import { onReady } from "@xatom/core";

import { loadAdminAuthFromStorage } from "./modules/auth/admin";
import { loadPublicAuthFromStorage } from "./modules/auth/public";
import { adminRoutes } from "./routes/admin";
import { initSidebar } from "./modules/common/sidebar";
import { publicRoutes } from "./routes/public";
import { loadSuperAdminAuthFromStorage } from "./modules/auth/superadmin";
import { superAdminRoutes } from "./routes/superadmin";

onReady(() => {
  try {
    loadAdminAuthFromStorage();
    loadPublicAuthFromStorage();
    loadSuperAdminAuthFromStorage();
  } catch (e) {
    console.error(e);
  }

  adminRoutes();
  publicRoutes();
  superAdminRoutes();
  initSidebar();
});
