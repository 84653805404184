import { navigate, WFRoute } from "@xatom/core";
import { PUBLIC_PATHS } from "../../config";
import {
  logoutPublicAuth,
  publicMiddleware,
} from "../../modules/auth/public";
import { loadMeData } from "../../modules/common/workspaceLoader";
import { initPublicHeader } from "../../modules/common/publicHeader";

export const publicRoutes = () => {
  new WFRoute(`${PUBLIC_PATHS.acceptInvite}`)
    .withMiddleware(publicMiddleware, "NONE", "allow", {
      onError: () => {
        navigate({
          to: PUBLIC_PATHS.dashboardOnly,
          type: "replace",
        });
      },
    })
    .execute((params: any) => {
      import("../../modules/public/auth/index")
        .then(({ acceptInvite }) => acceptInvite(params))
        .catch(console.log);
    });
  new WFRoute(`${PUBLIC_PATHS.login}`)
    .withMiddleware(publicMiddleware, "NONE", "allow", {
      onError: () => {
        navigate({
          to: PUBLIC_PATHS.dashboardOnly,
          type: "replace",
        });
      },
    })
    .execute(() => {
      import("../../modules/public/auth/index")
        .then(({ signIn }) => signIn())
        .catch(console.log);
    });
  new WFRoute(PUBLIC_PATHS.logout).execute(() => {
    logoutPublicAuth();
  });
  new WFRoute(PUBLIC_PATHS.dashboardOnly)
    .withMiddleware(publicMiddleware, "USER", "allow", {
      onError: () => {
        navigate({
          to: PUBLIC_PATHS.login,
          type: "replace",
        });
      },
    })
    .execute(() => {
      initPublicHeader();
      loadMeData((workspaceId: string) => {
        import("../../modules/public/dashboard/landing")
          .then(({ userLanding }) =>
            userLanding(workspaceId)
          )
          .catch(console.log);
      });
    });

  new WFRoute(`${PUBLIC_PATHS.dashboardOnly}/(.*)`)
    .withMiddleware(publicMiddleware, "USER", "allow", {
      onError: () => {
        navigate({
          to: PUBLIC_PATHS.login,
          type: "replace",
        });
      },
    })
    .execute(() => {
      initPublicHeader();
      loadMeData((workspaceId: string) => {
        new WFRoute(PUBLIC_PATHS.createTicket).execute(() =>
          import("../../modules/public/dashboard/create")
            .then(({ createTicket }) =>
              createTicket(workspaceId)
            )
            .catch(console.log)
        );
        new WFRoute(PUBLIC_PATHS.ticketDetails).execute(
          (params: { id: string; create: string }) =>
            import(
              "../../modules/public/dashboard/viewTask"
            )
              .then(({ viewTask }) =>
                viewTask(workspaceId, params)
              )
              .catch(console.log)
        );
        new WFRoute(PUBLIC_PATHS.notification).execute(
          (params: { status: string; type: string }) =>
            import(
              "../../modules/public/dashboard/notification"
            )
              .then(({ notification }) =>
                notification(workspaceId, params)
              )
              .catch(console.log)
        );
        new WFRoute(PUBLIC_PATHS.reports).execute(() =>
          import("../../modules/public/dashboard/reports")
            .then(({ reports }) => reports(workspaceId))
            .catch(console.log)
        );
        new WFRoute(PUBLIC_PATHS.tasks).execute(() =>
          import("../../modules/public/dashboard/tasks")
            .then(({ renderTaskList }) =>
              renderTaskList(workspaceId)
            )
            .catch(console.log)
        );
        new WFRoute(PUBLIC_PATHS.team).execute(() =>
          import("../../modules/public/dashboard/team")
            .then(({ Team }) => Team(workspaceId))
            .catch(console.log)
        );
      });
    });
};
