import {
  navigate,
  WFAuth,
  WFAuthMiddleware,
} from "@xatom/core";
import { ADMIN_PATHS } from "../../config";

const KEY = "@amply-auth-admin";
export const adminAuth = new WFAuth<
  {
    email: string;
    firstName: string;
    lastName: string;
    role: string;
  },
  "NONE" | "ADMIN",
  { token: string }
>();
adminAuth.setRole("NONE");

export const adminMiddleware = new WFAuthMiddleware(
  adminAuth
);

export const loadAdminAuthFromStorage = () => {
  const _data = JSON.parse(
    localStorage.getItem(KEY) || "{}"
  );
  if (
    "email" in _data &&
    "firstName" in _data &&
    "lastName" in _data &&
    "token" in _data &&
    "role" in _data
  ) {
    adminAuth.setUser({
      email: _data.email,
      firstName: _data.firstName,
      lastName: _data.lastName,
      role: _data.role,
    });
    adminAuth.setRole("ADMIN");
    adminAuth.setConfig({ token: _data.token });
  }
};

export const setAdminAuthDetails = (
  firstName: string,
  lastName: string,
  email: string,
  role: string,
  token: string
) => {
  adminAuth.setUser({
    email,
    firstName,
    lastName,
    role,
  });
  adminAuth.setRole("ADMIN");
  adminAuth.setConfig({ token });
  localStorage.setItem(
    KEY,
    JSON.stringify({
      firstName,
      lastName,
      email,
      token,
      role,
    })
  );
};

export const updateAdminAuthDetails = (
  firstName: string,
  lastName: string,
  email: string,
  role: string
) => {
  adminAuth.setUser({
    email,
    firstName,
    lastName,
    role,
  });
  adminAuth.setRole("ADMIN");
  let _data = JSON.parse(localStorage.getItem(KEY) || "{}");
  localStorage.setItem(
    KEY,
    JSON.stringify({
      ..._data,
      firstName,
      lastName,
      email,
      role,
    })
  );
};

export const logoutAdminAuth = () => {
  adminAuth.logout();
  localStorage.removeItem(KEY);
  navigate(ADMIN_PATHS.login);
};
