import { Notyf } from "notyf";

export const notify = new Notyf({
  position: {
    x: "center",
    y: "bottom",
  },
  types: [
    {
      className: "amp-notify",
      icon: {
        className: "amp-notify-icon-warn",
      },
      background: `#fff`,
      type: "warning",
      dismissible: true,
    },
    {
      className: "amp-notify",
      icon: {
        className: "amp-notify-icon-error",
      },
      background: `#fff`,
      type: "error",
      dismissible: true,
    },
    {
      className: "amp-notify",

      background: `#fff`,
      type: "info",
      dismissible: true,
    },
    {
      className: "amp-notify",
      background: `#fff`,
      type: "success",
      icon: {
        className: "amp-notify-icon-success",
      },
      dismissible: true,
    },
  ],
});
