import { WFComponent } from "@xatom/core";
import { adminAuth } from "../auth/admin";

export const initAdminHeader = () => {
  try {
    const header = new WFComponent(
      `.dashboard_header-section`
    );
    const data = adminAuth.getUser();

    header.updateTextViaAttrVar({
      adminName: data.firstName,
    });
  } catch (e) {
    console.log("Header not found");
  }
};
