import {
  navigate,
  WFAuth,
  WFAuthMiddleware,
} from "@xatom/core";
import { SUPER_ADMIN_PATHS } from "../../config";

const KEY = "@amply-auth-superadmin";
export const superAdminAuth = new WFAuth<
  {
    email: string;
    firstName: string;
    lastName: string;
    role: string;
  },
  "NONE" | "SUPER_ADMIN",
  { token: string }
>();
superAdminAuth.setRole("NONE");

export const superAdminMiddleware = new WFAuthMiddleware(
  superAdminAuth
);

export const loadSuperAdminAuthFromStorage = () => {
  const _data = JSON.parse(
    localStorage.getItem(KEY) || "{}"
  );
  if (
    "email" in _data &&
    "firstName" in _data &&
    "lastName" in _data &&
    "token" in _data &&
    "role" in _data
  ) {
    superAdminAuth.setUser({
      email: _data.email,
      firstName: _data.firstName,
      lastName: _data.lastName,
      role: _data.role,
    });
    superAdminAuth.setRole("SUPER_ADMIN");
    superAdminAuth.setConfig({ token: _data.token });
  }
};

export const setSuperAdminAuthDetails = (
  firstName: string,
  lastName: string,
  email: string,
  role: string,
  token: string
) => {
  superAdminAuth.setUser({
    email,
    firstName,
    lastName,
    role,
  });
  superAdminAuth.setRole("SUPER_ADMIN");
  superAdminAuth.setConfig({ token });
  localStorage.setItem(
    KEY,
    JSON.stringify({
      firstName,
      lastName,
      email,
      token,
      role,
    })
  );
};

export const updateSuperAdminAuthDetails = (
  firstName: string,
  lastName: string,
  email: string,
  role: string
) => {
  superAdminAuth.setUser({
    email,
    firstName,
    lastName,
    role,
  });
  superAdminAuth.setRole("SUPER_ADMIN");
  let _data = JSON.parse(localStorage.getItem(KEY) || "{}");
  localStorage.setItem(
    KEY,
    JSON.stringify({
      ..._data,
      firstName,
      lastName,
      email,
      role,
    })
  );
};

export const logoutSuperAdminAuth = () => {
  superAdminAuth.logout();
  localStorage.removeItem(KEY);
  navigate(SUPER_ADMIN_PATHS.login);
};
