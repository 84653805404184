import { publicQL } from "../../graphql";
import {
  NotificationSeen,
  UserGetUnseenNotificationCountDocument,
} from "../../graphql/graphql";

const req = publicQL.query(
  UserGetUnseenNotificationCountDocument
);

let _workspaceId = "";
let _count = 0;
let _isChanged = false;
let _lastSeenInfo: NotificationSeen = null;
let _listeners = new Set<
  (data: {
    count: number;
    isChanged: boolean;
    lastSeenInfo: NotificationSeen;
  }) => void
>();
export const initPublicNotification = (
  workspaceId: string
) => {
  req.fetch(
    {
      workspaceId,
    },
    "network-only"
  );
  _workspaceId = workspaceId;

  req.onData((res) => {
    // console.log(res);
    if (res.userGetUnseenNotificationCount !== _count) {
      _isChanged = true;
    } else {
      _isChanged = false;
    }

    _count = res.userGetUnseenNotificationCount;
    _lastSeenInfo = res.userGetLastSeenNotification;

    for (const listener of _listeners) {
      listener({
        count: _count,
        isChanged: _isChanged,
        lastSeenInfo: _lastSeenInfo,
      });
    }
  });
  setInterval(() => {
    if (!req.isLoading()) {
      req.fetch(
        {
          workspaceId,
        },
        "network-only"
      );
    }
  }, 5000);
};

export const onPublicNotificationCountChanged = (
  cb: (data: {
    count: number;
    isChanged: boolean;
    lastSeenInfo: NotificationSeen;
  }) => void
) => {
  _listeners.add(cb);
  cb({
    count: _count,
    isChanged: true,
    lastSeenInfo: _lastSeenInfo,
  });
  return () => {
    _listeners.delete(cb);
  };
};
