const ENDPOINT = window.location.host.includes("localhost")
  ? "http://localhost:4061"
  : window.location.host.includes("dev.")
  ? "https://dev-api.retainable.com"
  : "https://prod-api.retainable.com";
export const GQL_ENDPOINT = `${ENDPOINT}/api/graphql`;

export const ADMIN_PATH = `/admin`;
export const SUPER_ADMIN_PATH = `/superadmin`;
export const PUBLIC_PATH = ``;

export const ADMIN_PATHS = {
  login: `${ADMIN_PATH}/auth/sign-in`,
  dashboardOnly: `${ADMIN_PATH}/dashboard`,
  workspaceCreate: `${ADMIN_PATH}/dashboard/new-workspace`,
  workspaceManage: `${ADMIN_PATH}/dashboard/workspaces/manage`,
  workspaceList: `${ADMIN_PATH}/dashboard/workspaces`,
  acceptInvite: `${ADMIN_PATH}/auth/accept-invite`,
  team: `${ADMIN_PATH}/dashboard/team`,
};
export const SUPER_ADMIN_PATHS = {
  login: `${SUPER_ADMIN_PATH}/auth/sign-in`,
  acceptInvite: `${SUPER_ADMIN_PATH}/auth/accept-invite`,
  dashboardOnly: `${SUPER_ADMIN_PATH}/dashboard`,
  agencyCreate: `${SUPER_ADMIN_PATH}/dashboard/create-new-agency`,
  agencyManage: `${SUPER_ADMIN_PATH}/dashboard/agency`,
  team: `${SUPER_ADMIN_PATH}/dashboard/team`,
};
export const PUBLIC_PATHS = {
  login: `${PUBLIC_PATH}/auth/sign-in`,
  logout: `${PUBLIC_PATH}/logout`,
  acceptInvite: `${PUBLIC_PATH}/auth/accept-invite`,
  dashboardOnly: `${PUBLIC_PATH}/dashboard`,
  createTicket: `${PUBLIC_PATH}/dashboard/create-task`,
  ticketDetails: `${PUBLIC_PATH}/dashboard/task`,
  notification: `${PUBLIC_PATH}/dashboard/notifications`,
  reports: `${PUBLIC_PATH}/dashboard/reports`,
  tasks: `${PUBLIC_PATH}/dashboard/tasks`,
  team: `${PUBLIC_PATH}/dashboard/team`,
};

export const ASSET_ENDPOINT = `https://retainer-dashboard.s3.us-east-2.amazonaws.com`;

export const GOOGLE_CLIENT_ID =
  "194303065708-rt4hpknukcf51jbnsr8scm5lhhckqpn1.apps.googleusercontent.com";

export const CLICKUP_DEFAULT_TEAM =
  process.env.CLICKUP_TEAM_ID;

export const CLICKUP_LIST_URL =
  "https://app-cdn.clickup.com/10581263/v/li/";

export const prioritiesMap = [
  {
    name: "-",
    color: "#C9C9C9",
  },
  {
    name: "Urgent",
    color: "#D23F3F",
  },
  {
    name: "High",
    color: "#F0B740",
  },
  {
    name: "Normal",
    color: "#67C076",
  },
  {
    name: "Low",
    color: "#C9C9C9",
  },
];
