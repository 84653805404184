import { WFComponent } from "@xatom/core";

export const initSidebarBranding = (data: {
  logoLg: string;
  logoSm: string;
  name: string;
}) => {
  const { logoLg, logoSm, name } = data;
  const el = document.querySelector(`[xa-type="sidebar"]`);
  if (!el) return;

  const sidebar = new WFComponent(el);

  const logoLgEl =
    sidebar.getChildAsComponent<HTMLImageElement>(
      `img[xa-type="logo-lg"]`
    );
  const logoSmEl =
    sidebar.getChildAsComponent<HTMLImageElement>(
      `img[xa-type="logo-sm"]`
    );

  logoLgEl.setAttribute("src", logoLg);
  logoSmEl.setAttribute("src", logoSm);
  logoLgEl.setAttribute("alt", name);
  logoSmEl.setAttribute("alt", name);

  //preload and cache images in background
  const preloadImages = () => {
    const imgLg = new Image();
    imgLg.src = logoLg;
    const imgSm = new Image();
    imgSm.src = logoSm;
  };
  preloadImages();
  sidebar.setAttribute("r-branding-ready", "true");
};
