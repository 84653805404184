import { WFComponent } from "@xatom/core";

import { publicAuth } from "../auth/public";

export const initPublicHeader = () => {
  try {
    const header = new WFComponent(
      `.dashboard_header-section`
    );
    const data = publicAuth.getUser();

    header.updateTextViaAttrVar({
      adminName: data.firstName,
    });
  } catch (e) {
    console.log("Header not found");
  }
};
