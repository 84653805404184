import { WFComponent } from "@xatom/core";
import { logoutPublicAuth } from "../auth/public";
import { logoutAdminAuth } from "../auth/admin";
import { onPublicNotificationCountChanged } from "./publicNotification";
import { logoutSuperAdminAuth } from "../auth/superadmin";

export const initSidebar = () => {
  const isAdmin =
    window.location.pathname.startsWith("/admin");
  const isSuperAdmin =
    window.location.pathname.startsWith("/superadmin");
  if (document.querySelector(`[xa-type="sidebar"]`)) {
    const sidebar = new WFComponent(`[xa-type="sidebar"]`);
    let status = sidebar.getAttribute("xa-config");
    const toggle = sidebar.getChildAsComponent(
      `[xa-type="sidebar-toggle"]`
    );

    if (localStorage.getItem("@arw-sidebar") === "close") {
      sidebar.setAttribute("xa-config", "close");
      status = "close";
    }

    toggle.on("click", () => {
      if (status === "open") {
        sidebar.setAttribute("xa-config", "close");
        localStorage.setItem("@arw-sidebar", "close");
        status = "close";
      } else {
        sidebar.setAttribute("xa-config", "open");
        localStorage.setItem("@arw-sidebar", "open");
        status = "open";
      }
    });
    if (window.innerWidth <= 767) {
      status = "close";
      sidebar.setAttribute("xa-config", "close");
      localStorage.setItem("@arw-sidebar", "close");
    }

    if (isAdmin) {
      const logoutBtn = new WFComponent(
        `[xa-type="logout"]`
      );
      logoutBtn.on("click", () => {
        logoutAdminAuth();
      });
    } else if (isSuperAdmin) {
      const logoutBtn = new WFComponent(
        `[xa-type="logout"]`
      );
      logoutBtn.on("click", () => {
        logoutSuperAdminAuth();
      });
    } else {
      const logoutBtn = new WFComponent(
        `[xa-type="logout"]`
      );
      logoutBtn.on("click", () => {
        logoutPublicAuth();
      });

      const notificationTag = new WFComponent(
        `[xa-type="notification-tag"]`
      );
      notificationTag.addCssClass("hide");

      onPublicNotificationCountChanged(
        ({ count, isChanged }) => {
          if (isChanged) {
            if (count === 0) {
              notificationTag.addCssClass("hide");
            } else {
              notificationTag.removeCssClass("hide");
            }
            notificationTag.updateTextViaAttrVar({
              "tag-text": count,
            });
          }
        }
      );
    }
  }
};
