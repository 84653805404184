import {
  navigate,
  WFAuth,
  WFAuthMiddleware,
} from "@xatom/core";
import { PUBLIC_PATHS } from "../../config";

const KEY = "@amply-auth-public";
export const publicAuth = new WFAuth<
  {
    email: string;
    firstName: string;
    lastName: string;
  },
  "NONE" | "USER",
  { token: string }
>();
publicAuth.setRole("NONE");

export const publicMiddleware = new WFAuthMiddleware(
  publicAuth
);

export const loadPublicAuthFromStorage = () => {
  const _data = JSON.parse(
    localStorage.getItem(KEY) || "{}"
  );
  if (
    "email" in _data &&
    "firstName" in _data &&
    "lastName" in _data &&
    "token" in _data
  ) {
    publicAuth.setUser({
      email: _data.email,
      firstName: _data.firstName,
      lastName: _data.lastName,
    });
    publicAuth.setRole("USER");
    publicAuth.setConfig({ token: _data.token });
  }
};

export const setPublicAuthDetails = (
  firstName: string,
  lastName: string,
  email: string,
  token: string
) => {
  publicAuth.setUser({
    email,
    firstName,
    lastName,
  });
  publicAuth.setRole("USER");
  publicAuth.setConfig({ token });
  localStorage.setItem(
    KEY,
    JSON.stringify({ firstName, lastName, email, token })
  );
};

export const updatePublicAuthDetails = (
  firstName: string,
  lastName: string,
  email: string
) => {
  publicAuth.setUser({
    email,
    firstName,
    lastName,
  });
  publicAuth.setRole("USER");
  let _data = JSON.parse(localStorage.getItem(KEY) || "{}");
  localStorage.setItem(
    KEY,
    JSON.stringify({ ..._data, firstName, lastName, email })
  );
};

export const logoutPublicAuth = () => {
  publicAuth.logout();
  localStorage.removeItem(KEY);
  navigate(PUBLIC_PATHS.login);
};
