import { WFComponent, WFDynamicList } from "@xatom/core";
import { publicQL } from "../../graphql";
import {
  MeDocument,
  UserWorkspaceWithStats,
} from "../../graphql/graphql";
import { initPublicNotification } from "./publicNotification";
import { notify } from "../utils/noti";
import { ASSET_ENDPOINT } from "../../config";
import { initSidebarBranding } from "../utils/sidebarBranding";
const req = publicQL.query(MeDocument);
let _workspaceId = "";
export const loadMeData = async (
  cb: (workspaceId: string) => void
) => {
  req.onData((data) => {
    const id = getDefaultWorkspace();
    if (id) {
      _workspaceId = id;
      initWorkspaceSwitch();
      const workspace = data.userGetAllWorkspaces.filter(
        (d) => d.workspaceId === id
      )[0];
      if (workspace) {
        initSidebarBranding({
          logoLg: `${ASSET_ENDPOINT}/${workspace.workspace.agency.logoLg}`,
          logoSm: `${ASSET_ENDPOINT}/${workspace.workspace.agency.logoSm}`,
          name: workspace.workspace.agency.name,
        });
      }

      cb(id);
      initPublicNotification(id);
    }
  });
  req.onError((err) => {
    console.error(err);
  });
  req.fetch();
};

export const getMeData = () => req.data();

export const setDefaultWorkspace = (
  workspaceId: string
) => {
  if (!req.data()) return;
  if (
    req
      .data()
      .userGetAllWorkspaces.filter(
        (d) => d.workspaceId === workspaceId
      ).length === 0
  )
    return;
  localStorage.setItem("@amply-public-w", workspaceId);
  _workspaceId = workspaceId;
};

export const getDefaultWorkspace = () => {
  const id = localStorage.getItem("@amply-public-w");
  if (id) {
    const defaultWorkspace = req
      .data()
      .userGetAllWorkspaces.filter(
        (d) => d.workspaceId === id
      )[0];

    if (defaultWorkspace) {
      _workspaceId = defaultWorkspace.workspaceId;
      localStorage.setItem("@amply-public-w", _workspaceId);

      return _workspaceId;
    }
  }
  const defaultWorkspace =
    req.data().userGetAllWorkspaces[0];
  if (!defaultWorkspace) {
    notify.error("You don't have access to any workspace");
    return undefined;
  }
  localStorage.setItem(
    "@amply-public-w",
    defaultWorkspace.workspaceId
  );
  _workspaceId = defaultWorkspace.workspaceId;
  return _workspaceId;
};

export const hasWorkspace = () => {
  return getDefaultWorkspace() ? true : false;
};

export const getWorkspaceData = (id: string) => {
  return req
    .data()
    .userGetAllWorkspaces.filter(
      (d) => d.workspaceId === id
    )[0];
};

export const initWorkspaceSwitch = () => {
  const list = new WFDynamicList<{
    id: string;
    name: string;
    enabled: boolean;
  }>(`[xa-type="workspace-switch-list"]`, {
    rowSelector: `[xa-type="workspace-switch-item"]`,
  });
  list.rowRenderer(({ rowData, rowElement }) => {
    const text = rowElement.getChildAsComponent(
      `[xa-type="link-text"]`
    );
    const isSelected = rowData.id === _workspaceId;
    text.setTextContent(
      isSelected ? `✅ ${rowData.name}` : rowData.name
    );
    rowElement.on("click", () => {
      if (isSelected) return;
      setDefaultWorkspace(rowData.id);
      window.location.reload();
    });
    return rowElement;
  });
  const workspaces = req
    .data()
    .userGetAllWorkspaces.map((d) => d.workspace);
  if (workspaces.length === 1) {
    list.getElement().parentElement.remove();
  } else {
    list
      .getElement()
      .parentElement.classList.remove("hide");
    list.setData(workspaces);
  }
};
