import {
  createHttpLink,
  ApolloLink,
  onError,
  ApolloClient,
  from,
  InMemoryCache,
  createUploadLink,
} from "@xatom/apollo-graphql";
import { adminAuth, logoutAdminAuth } from "../auth/admin";
import {
  logoutPublicAuth,
  publicAuth,
} from "../auth/public";

import { GQL_ENDPOINT } from "../../config";
import {
  logoutSuperAdminAuth,
  superAdminAuth,
} from "../auth/superadmin";

const parseHeaders = (rawHeaders: any) => {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(
    /\r?\n[\t ]+/g,
    " "
  );
  preProcessedHeaders
    .split(/\r?\n/)
    .forEach((line: any) => {
      const parts = line.split(":");
      const key = parts.shift().trim();
      if (key) {
        const value = parts.join(":").trim();
        headers.append(key, value);
      }
    });
  return headers;
};

export const uploadFetch = (url: string, options: any) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const opts: any = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(
          xhr.getAllResponseHeaders() || ""
        ),
      };
      opts.url =
        "responseURL" in xhr
          ? xhr.responseURL
          : opts.headers.get("X-Request-URL");
      const body =
        "response" in xhr
          ? xhr.response
          : (xhr as any).responseText;
      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError("Network request failed"));
    };
    xhr.ontimeout = () => {
      reject(new TypeError("Network request failed"));
    };
    xhr.open(options.method, url, true);

    Object.keys(options.headers).forEach((key) => {
      xhr.setRequestHeader(key, options.headers[key]);
    });

    if (xhr.upload) {
      xhr.upload.onprogress =
        "onProgress" in options
          ? options.onProgress
          : () => {};
    }
    if ("onAbortPossible" in options)
      options.onAbortPossible(() => {
        xhr.abort();
      });

    xhr.send(options.body);
  });

const customFetch = (uri: any, options: any) => {
  if (options.useUpload) {
    return uploadFetch(uri, options);
  }
  return fetch(uri, options);
};

const adminHttpLink = createUploadLink({
  credentials: "include",
  uri: GQL_ENDPOINT,
});
const publicHttpLink = createUploadLink({
  credentials: "include",
  uri: GQL_ENDPOINT,
  fetch: customFetch as any,
});

const superAdminHttpLink = createUploadLink({
  credentials: "include",
  uri: GQL_ENDPOINT,
  fetch: customFetch as any,
});

const addAdminToken = new ApolloLink(
  (operation, forward) => {
    if (adminAuth.isLoggedIn()) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          authorization: `Bearer ${
            adminAuth.getConfig().token
          }`,
        },
      }));
    } else {
      console.log("admin is not logged in", publicAuth);
    }

    return forward(operation);
  }
);

const addPublicToken = new ApolloLink(
  (operation, forward) => {
    if (publicAuth.isLoggedIn()) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          authorization: `Bearer ${
            publicAuth.getConfig().token
          }`,
        },
      }));
    } else {
      console.log("user is not logged in", publicAuth);
    }

    return forward(operation);
  }
);

const addSuperAdminToken = new ApolloLink(
  (operation, forward) => {
    if (superAdminAuth.isLoggedIn()) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          authorization: `Bearer ${
            superAdminAuth.getConfig().token
          }`,
        },
      }));
    } else {
      console.log(
        "super admin is not logged in",
        superAdminAuth
      );
    }

    return forward(operation);
  }
);

const onAdminError = onError(
  ({ graphQLErrors, forward, operation }) => {
    for (let err of graphQLErrors) {
      console.log(err);
      if (err.message === "Authentication failed") {
        //handle the error
        logoutAdminAuth();
        console.log("handle admin error");
      }
    }
    return forward(operation);
  }
);
const onPublicError = onError(
  ({ graphQLErrors, forward, operation }) => {
    console.log(graphQLErrors);
    for (let err of graphQLErrors) {
      console.log(err);
      if (err.message === "Authentication failed") {
        //handle the error
        logoutPublicAuth();
        console.log("handle public error");
      }
    }
    return forward(operation);
  }
);

const onSuperAdminError = onError(
  ({ graphQLErrors, forward, operation }) => {
    console.log(graphQLErrors);
    for (let err of graphQLErrors) {
      console.log(err);
      if (err.message === "Authentication failed") {
        //handle the error
        logoutSuperAdminAuth();
        console.log("handle super admin error");
      }
    }
    return forward(operation);
  }
);

export const adminClient = new ApolloClient({
  link: from([
    addAdminToken,
    onAdminError,
    adminHttpLink as any,
  ]),
  cache: new InMemoryCache(),
});

export const publicClient = new ApolloClient({
  link: from([
    addPublicToken,
    onPublicError,
    publicHttpLink as any,
  ]),
  cache: new InMemoryCache(),
});

export const superAdminClient = new ApolloClient({
  link: from([
    addSuperAdminToken,
    onSuperAdminError,
    superAdminHttpLink as any,
  ]),
  cache: new InMemoryCache(),
});
