import { GraphQLClient } from "@xatom/apollo-graphql";
import {
  adminClient,
  publicClient,
} from "../modules/apollo";
import { superAdminClient } from "../modules/apollo";

export const adminQL = new GraphQLClient(adminClient);
export const publicQL = new GraphQLClient(publicClient);
export const superAdminQL = new GraphQLClient(
  superAdminClient
);
