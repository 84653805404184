import { WFComponent } from "@xatom/core";

import { superAdminAuth } from "../auth/superadmin";

export const initSuperAdminHeader = () => {
  try {
    const header = new WFComponent(
      `.dashboard_header-section`
    );
    const data = superAdminAuth.getUser();

    header.updateTextViaAttrVar({
      adminName: data.firstName,
    });
  } catch (e) {
    console.log("Header not found");
  }
};
