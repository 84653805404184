const CACHE_KEY = "@r-cache:";

export type CacheValue =
  | {
      data: string;
      type: "string";
    }
  | {
      data: number;
      type: "number";
    }
  | {
      data: boolean;
      type: "boolean";
    }
  | {
      data: any;
      type: "json";
    };

const setCache = (
  key: string,
  value: CacheValue,
  expireAt: number = 0
) => {
  const _key = `${CACHE_KEY}${key}`;
  const _expireAt =
    expireAt >= 0 ? Date.now() + expireAt : 0;
  localStorage.setItem(
    _key,
    JSON.stringify({ value, expireAt: _expireAt })
  );
};

const getCache = (
  key: string,
  returnExpired: boolean = false
): CacheValue | null => {
  const _key = `${CACHE_KEY}${key}`;
  const value = localStorage.getItem(_key);
  if (!value) return null;
  const _data = JSON.parse(value);
  if (_data.expireAt && _data.expireAt < Date.now()) {
    if (returnExpired) return _data.value;
    clearCache(key);
    return null;
  }
  return _data.value;
};

const clearCache = (key: string) => {
  const _key = `${CACHE_KEY}${key}`;
  localStorage.removeItem(_key);
};

const hasCache = (key: string) => {
  const _key = `${CACHE_KEY}${key}`;
  return localStorage.getItem(_key) !== null;
};

export const browserCache = {
  setCache,
  getCache,
  clearCache,
  hasCache,
};
