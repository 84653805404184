import { navigate, WFRoute } from "@xatom/core";

import { initSuperAdminHeader } from "../../modules/common/superAdminHeader";
import {
  SUPER_ADMIN_PATH,
  SUPER_ADMIN_PATHS,
} from "../../config";
import { superAdminMiddleware } from "../../modules/auth/superadmin";

export const superAdminRoutes = () => {
  new WFRoute(`${SUPER_ADMIN_PATH}/(.*)`).execute(() => {
    new WFRoute(SUPER_ADMIN_PATHS.login)
      .withMiddleware(
        superAdminMiddleware,
        "NONE",
        "allow",
        {
          onError: () => {
            navigate({
              to: SUPER_ADMIN_PATHS.dashboardOnly,
              type: "replace",
            });
          },
        }
      )
      .execute(() =>
        import("../../modules/superadmin/auth/signIn")
          .then(({ superAdminSign }) => superAdminSign())
          .catch(console.log)
      );
    new WFRoute(SUPER_ADMIN_PATHS.acceptInvite)
      .withMiddleware(
        superAdminMiddleware,
        "NONE",
        "allow",
        {
          onError: () => {
            navigate({
              to: SUPER_ADMIN_PATHS.dashboardOnly,
              type: "replace",
            });
          },
        }
      )
      .execute((params: any) =>
        import("../../modules/superadmin/auth/invite")
          .then(({ superAdminAcceptInvite }) =>
            superAdminAcceptInvite(params)
          )
          .catch(console.log)
      );

    new WFRoute(SUPER_ADMIN_PATHS.dashboardOnly)
      .withMiddleware(
        superAdminMiddleware,
        "SUPER_ADMIN",
        "allow",
        {
          onError: () => {
            navigate({
              to: SUPER_ADMIN_PATHS.login,
              type: "replace",
            });
          },
        }
      )
      .execute(() => {
        initSuperAdminHeader();

        import("../../modules/superadmin/dashboard/landing")
          .then(({ superAdminLanding }) =>
            superAdminLanding()
          )
          .catch(console.log);
      });
    new WFRoute(`${SUPER_ADMIN_PATHS.dashboardOnly}/(.*)`)
      .withMiddleware(
        superAdminMiddleware,
        "SUPER_ADMIN",
        "allow",
        {
          onError: () => {
            navigate({
              to: SUPER_ADMIN_PATHS.login,
              type: "replace",
            });
          },
        }
      )
      .execute(() => {
        initSuperAdminHeader();

        new WFRoute(SUPER_ADMIN_PATHS.agencyCreate).execute(
          () =>
            import(
              "../../modules/superadmin/agency/createAgency"
            )
              .then(({ createAgency }) => createAgency())
              .catch(console.log)
        );
        new WFRoute(SUPER_ADMIN_PATHS.agencyManage).execute(
          ({ id }) =>
            import(
              "../../modules/superadmin/agency/manageAgency"
            )
              .then(({ manageAgency }) => manageAgency(id))
              .catch(console.log)
        );

        // new WFRoute(SUPER_ADMIN_PATHS.workspaceCreate).execute(
        //   () =>
        //     import("../../modules/superadmin/workspace/create")
        //       .then(({ superAdminWorkspaceCreate }) =>
        //         superAdminWorkspaceCreate()
        //       )
        //       .catch(console.log)
        // );
        // new WFRoute(
        //   `${SUPER_ADMIN_PATHS.workspaceManage}`
        // ).execute(({ id }) =>
        //   import("../../modules/superadmin/workspace/manage")
        //     .then(({ superAdminWorkspaceManage }) =>
        //       superAdminWorkspaceManage(id)
        //     )
        //     .catch(console.log)
        // );
        // new WFRoute(`${SUPER_ADMIN_PATHS.team}`).execute(() =>
        //   import("../../modules/superadmin/team/team")
        //     .then(({ manageTeam }) => manageTeam())
        //     .catch(console.log)
        // );
      });
  });
};
